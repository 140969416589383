import React from 'react'
import { Link } from 'gatsby'

// Styles 
import { SeoTextHolder } from "./SeoText.styles"

const SeoText = () => ( 
    <SeoTextHolder>
        <span>Om oss</span>
        <h2>Flyttoffferten</h2>
        <p>Letar du efter en flyttfirma så har du kommit Rätt. 
            Flyttofferten.se är Sveriges bästa hemsida med 
            att hitta rätt flyttfirma. Alla företag vi samarbetar 
            med har yrkestrafiktillstånd och försäkringar så att du 
            som kund ska känna dig trygg. <br/>
        </p>

    </SeoTextHolder>
 
)

export default SeoText