import React from "react"
import { graphql, useStaticQuery } from 'gatsby'




// Components
import Layout from 'components/Layout'
import SEO from "components/SEO"
import SeoText from "components/SeoText"
import HeroTwoSides from "components/HeroTwoSides"
import IconThree from "components/IconThree"
import OffertForm from "components/OffertForm"


const IndexPage = ({data}) => {
 

const schema = {
  
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Flyttofferten",
    "description": "Letar du efter flyttfirma? Flyttofferten hittar dig flyttfirma som passar dig bäst. Fyll i formulär och luta dig tillbaka, vi skickar de 5 bästa erbjudande.", 
    "department": [
      "Flyttfirma Göteborg",
      "Flyttfirma Borås",
      "Flyttfirma Alingsås",
      "Flyttfirma Jönköping",
      "Flyttfirma Kungsbacka",
      "Flyttfirma Linköping",
      "Flyttfirma Trollhättan",
      "Flyttfirma Udevalla",
      "Flyttfirma Varberg",
      "Flyttfirma Växjö",
    ],
    "location": [
      "Göteborg",
      "Borås",
      "Alingsås",
      "Jönköping",
      "Kungsbacka",
      "Linköping",
      "Trollhättan",
      "Udevalla",
      "Varberg",
      "Växjö"
    ],
    "url": "https://flyttofferten.se/",
    "logo": "https://flyttofferten.se/static/261d75957584a7c9ad2791898a80f280/logo.png"
  
}

return(
  <Layout>
    <SEO title="Flyttofferten hittar flyttfirma som passar dig bäst " schemaMarkup={schema} />
    <HeroTwoSides /> 
    <OffertForm />   
    <SeoText />
    <IconThree />
    
    

       
  </Layout>
)
  
}

export default IndexPage

