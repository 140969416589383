import React from 'react'

// Styles
import { OffertHolder } from "./Offertform.styles"

const Offertform = () => (
    <OffertHolder>
        <h2 id="offert">Fyll i offertförfrågan</h2>
        <form 
        name="Flyttofferten"
        method="POST"
        action="/success" 
        data-netlify-honeypot="bot-field" 
        data-netlify="true" >
         <input type="hidden" name="form-name" value="Flyttofferten"/>
          <div className="mina-uppgifter" >
            <div className="row-del">
                <label>Privat<input type="checkbox" name="Privat" value="Ja Privat" /></label>
                <label>Företag<input type="checkbox" name="Foretag" value="Ja Företag" /></label>
                <label>Förmedlare<input type="checkbox" name="Formedlare" value="Ja Förmedlare" /></label>
            </div>
                
            <div className="row-del del-tva">
                <input placeholder="Namn" type="text" name="Namn" id="name" />
                <input placeholder="Telefon" type="number" name="Telefon" id="telefon" required/>
                <input placeholder="Epost" type="text" name="Epost" id="email" />
            </div>
            
           
            
             </div> {/* end mina-uppgifter */}

            <div className="flytt">
                <div className="flytta-fran">
                 <h2>FLYTTAR IFRÅN</h2>

                    <div className="row-del">
                        <input placeholder="Stad" type="text" name="Från_Stad" id="Stad_fran" />
                        <input placeholder="Rum" type="text" name="Från_Rum" id="Rum_fran" />
                        <input placeholder="Våning" type="text" name="Från_Våning" id="Vaning_fran" />
                        <input placeholder="Boarea" type="text" name="Från_Boarea" id="Boarea_fran" />

                    </div>
                </div>
                <div className="form-end-desk">
                    <textarea placeholder="Kommentar & datum för flytt " name="Komentar" id="textarea" ></textarea>

                    <div className="gdpr">
                        <input type="checkbox" name="checkbox" required value="
                        Genom att jag har skickat detta meddelande samtycker jag till att 
                        ni tar del av de personuppgifter jag har valt att delge.
                        "/>
                        <label>
                        Flyttofferten.se lagrar bokningshistorik och personuppgifter som 
                        kunden lämnar och behandlar dessa uppgifter i enlighet med gällande 
                        GDPR (General Data Protection Regulation). Genom att skicka offertförfågan 
                        godkänner du användaravtalet.
                        </label>
                    </div>

                     <input className="button " type="submit" value="Skicka" />
                </div>
                <div className="flytta-till">
                    <h2>FLYTTAR TILL</h2>
                    
                    <div className="row-del">
                         <input placeholder="Stad" type="text" name="Till_Stad" id="Stad_till" />
                         <input placeholder="Rum" type="text" name="Till_Rum" id="Rum_till" />
                         <input placeholder="Våning" type="text" name="Till_Våning" id="Vaning_till" />
                         <input placeholder="Boarea" type="text" name="Till_Boarea" id="Boarea_till" />

                    </div>   
                </div>    
            </div>
      </form>
    </OffertHolder>

)

export default Offertform