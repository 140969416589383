import React from 'react'
import { Link } from 'gatsby'
// import Image from "gatsby-image"
//import { FaFacebookSquare, FaInstagram, FaMailBulk } from 'react-icons/fa';
import 'animate.css/animate.css'

// Styles
import { HeroSides, Logo } from "./HeroTwoSides.styles"

//Hooks 
import { useBilderDataQuery } from 'Hooks/useBilderQuery'
import { useSiteConfigQuery } from 'Hooks/useSiteConfigQuery'


const HeroTwoSides = () => {
    const siteConfig = useSiteConfigQuery()
    const { 
        herobg
        
    } = useBilderDataQuery()

    const hero = herobg.childImageSharp.fluid.src;

    return (
    <HeroSides style={{background:'linear-gradient(to right, rgba(3,7,29,.95),rgba(9,64,110,.90)),url('+ hero +')'}}>    
        <div className="left" >
        <Logo src={siteConfig.logo.publicURL} alt="Flyttofferten" />

        </div>
        <div className="right">
            <h1><strong>Välkommen</strong> till Sveriges 
                bästa hemsida för att hitta 
                rätt <strong>flyttfirma</strong> </h1>
            <div className="knappar"> 
                <Link to="#offert" className="animate__animated animate__fadeIn animate__delay-1s offert-knapp">
                    Offert
                </Link>
                <Link to="#kontakt" className="animate__animated animate__fadeIn animate__delay-2s">
                    Kontakt
                </Link>     
            </div>
            <p className="nationell">Flyttar du lokalt nationellt eller utomlands, vi samarbetar med 5 olika flyttfirmor som är proffs med allt inom flytt samt flyttstäd</p>
        </div>
    </HeroSides>
)
}


export default HeroTwoSides 