import styled from "styled-components"

export const HeroSides = styled.div`

width:100%;
height:100%;
min-height: 100vh;
position:relative;
background-position:center !important;
background-size:cover !important;
display:flex;
justify-content:flex-start;
align-items:center;
flex-direction:column;
padding-top:35px;

@media screen and (min-width: 1024px)  {
    flex-direction:row;
    justify-content:center;
    padding-top:0;
}
// @media screen and (max-width: 768px)  {
//     height: 50vh;
// }


.left {
    width:50%;
    display:flex;
    justify-content:center;
    align-items:center;

    img {
        width: 250px;
        margin: 0;
        @media screen and (min-width: 1024px)  {
            width: 350px;
        }
    }
}
.right {
    width: 100%;
    display:flex;
    justify-content:flex-start;
    align-items:flex-start;
    flex-direction:column;

    @media screen and (min-width: 1024px)  {
        width: 50%;
        
    }

    h1 {
        color: white;
        font-size: 2rem;
        font-weight: 300;
        text-align:center;
        padding: 10px;


        @media screen and (min-width: 1024px)  {
            font-size:3.5rem;
            text-align: left;
            padding:0;
            
        }
    }

    .hero-right-subpage {
        font-size:3rem;
      }

    p {
        font-size: 2rem;
        text-align: center;
        padding: 15px;
        color: white;
        font-weight:200;
        line-height: 1.3;

        @media screen and (min-width: 1024px)  {
            font-size:3.5rem;
            text-align: left;
            
        }
    }

    .knappar {
        display:flex;
        flex-direction:column;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding-left: 0;
        
        @media screen and (max-width: 767px)  {
            width: 100%;
            max-width:700px;
         }
    
        @media screen and (min-width: 1024px)  {
           width:100%;
           flex-direction: row;
           max-width:800px;
           justify-content: flex-start;
           padding-left: 15px;
        }
    
        a {
            background:transparent;
            color:white;
            font-weight:bold;
            transition:.3s;
            padding:7px 15px;
            border: 1px solid white;
            flex: 1 1 0;
            margin-right:0px;
            font-family:'Nunito', sans-serif;
            text-transform:uppercase;
            text-align:center;
            border-radius:50px;
            font-weight: 200;
            padding: 10px;
            font-size: 1.2rem;
            margin-bottom: 15px;
            width: 100%;
            max-width: 175px;

            @media screen and (min-width: 1024px)  {
            margin-right: 50px;
            }
            
    
            :hover {
                color: black;
                background: #ccaf89;
                border:1px solid #ccaf89;
            }
        }
        
    }
    .nationell {
        font-size: 1rem;
    }
}



`
export const Logo = styled.img`
width:150px;
margin-top:100px;
  @media screen and (min-width: 1024px) {
    width:200px;
  }
`