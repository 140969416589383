import React from 'react'
import { Link } from 'gatsby'
import Image from "gatsby-image"

// Styles 
import { IconsHolder } from "./IconThree.styles"

import { useBilderDataQuery } from 'Hooks/useBilderQuery'

const IconThree = () => {
    const { 
        iconone,
        icontwo,
        iconthree
        
    } = useBilderDataQuery()

    const form = iconone.childImageSharp.fluid;
    const moln = icontwo.childImageSharp.fluid;
    const mail = iconthree.childImageSharp.fluid;

return(
    <IconsHolder>
        <section>
            <Image className="" fluid={form} alt="Flyttofferten" />
            <h2>Skicka offert</h2>
            <p> Nedan fyller du i 
                alla uppgifter angående 
                din flytt</p>
        </section>
        <section>
            <Image className="" fluid={moln} alt="Flyttoffertenp" />
            <h2>2h</h2>
            <p> Vi <a href="https://www.byggboras.nu/flyttfirma-boras">kontaktar samtliga flyttfirmor</a>
 som vi samarbetar med och 
analyserar deras offert</p>
        </section>
        <section>
            <Image className="" fluid={mail} alt="Flyttofferten" />
            <h2>Vi svarar</h2>
            <p> Inom 2h får du offerter från 5 olika företag och välj du den som passar dig bäst</p>
        </section>
        
    </IconsHolder>
    )
}

export default IconThree
